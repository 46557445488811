import {
  FaFileArchive,
  FaFileAudio,
  FaFileCode,
  FaFileExcel,
  FaFileImage,
  FaFileCsv,
  FaFilePdf,
  FaFileWord,
  FaFilePowerpoint,
  FaFileVideo,
  FaFileAlt,
  FaFile,
} from 'react-icons/fa';

export const mimeToFileIcon: Record<string, React.FC> = {
  'application/zip': FaFileArchive,
  'application/x-rar-compressed': FaFileArchive,
  'application/x-7z-compressed': FaFileArchive,
  'audio/mpeg': FaFileAudio,
  'audio/wav': FaFileAudio,
  'text/html': FaFileCode,
  'application/javascript': FaFileCode,
  'application/json': FaFileCode,
  'application/vnd.ms-excel': FaFileExcel,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    FaFileExcel,
  'image/jpeg': FaFileImage,
  'image/png': FaFileImage,
  'image/gif': FaFileImage,
  'image/webp': FaFileImage,
  'text/csv': FaFileCsv,
  'application/pdf': FaFilePdf,
  'application/msword': FaFileWord,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FaFileWord,
  'application/vnd.ms-powerpoint': FaFilePowerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    FaFilePowerpoint,
  'video/mp4': FaFileVideo,
  'video/x-msvideo': FaFileVideo,
  'text/plain': FaFileAlt,
  'application/x-zip-compressed': FaFileArchive,
  'application/x-pdf': FaFilePdf,
  'application/octet-stream': FaFile,
  'application/x-compressed': FaFileArchive,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    FaFileWord,
  'application/vnd.openxmlformats-officedocument.presentationml.template':
    FaFilePowerpoint,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    FaFileExcel,
  'image/svg+xml': FaFileImage,
  'application/x-tar': FaFileArchive,
  'audio/ogg': FaFileAudio,
  'video/ogg': FaFileVideo,
  'application/x-mpegURL': FaFileVideo,
  'video/mp2t': FaFileVideo,
  'text/xml': FaFileCode,
  'application/vnd.oasis.opendocument.text': FaFileWord,
  'application/vnd.oasis.opendocument.spreadsheet': FaFileExcel,
  'application/vnd.oasis.opendocument.presentation': FaFilePowerpoint,
};
