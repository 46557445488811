import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { applicationList, ApplicationModel } from '../data/application';
import {
  getWorkspaceShareById,
  listWorkspaces,
  WorkspaceModel,
} from '../data/workspace';
import { getFileList, RegisterFileResponse } from '../data/uploadFile';
import { RootState } from '.';
import { ShareModel } from '../data/share';

export interface IWorkspaceState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingApps: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingFiles: 'idle' | 'pending' | 'succeeded' | 'failed';
  error?: SerializedError;
  workspace?: WorkspaceModel;
  workspaceApps?: ApplicationModel[];
  workspaceShare?: ShareModel | undefined;
  workspaceFiles?: RegisterFileResponse[];
  workspaceList: WorkspaceModel[];
}

const initialState: IWorkspaceState = {
  loading: 'idle',
  loadingApps: 'idle',
  loadingFiles: 'idle',
  workspace: undefined,
  workspaceShare: undefined,
  workspaceFiles: [],
  workspaceList: [],
  workspaceApps: [],
};

const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<any>) => {
      state.workspace = state.workspaceList.find(
        (i) => i._id === action.payload,
      );
      state.workspaceShare = undefined;
      state.workspaceFiles = [];
    },
    setApps: (state, action: PayloadAction<ApplicationModel[]>) => {
      state.workspaceApps = action.payload;
    },
    addFile: (state, action: PayloadAction<RegisterFileResponse>) => {
      state.workspaceFiles = [...(state.workspaceFiles || []), action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.workspaceList = action.payload;
        if (state.workspace)
          state.workspace = action.payload.find(
            (i) => i._id === state.workspace?._id,
          );
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error;
        state.workspaceList = [];
      })
      .addCase(fetchApps.pending, (state, action) => {
        state.loadingApps = 'pending';
      })
      .addCase(fetchApps.fulfilled, (state, action) => {
        state.workspaceApps = action.payload;
        state.loadingApps = 'succeeded';
      })
      .addCase(fetchFiles.pending, (state, action) => {
        state.loadingFiles = 'pending';
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.workspaceFiles = action.payload;
        state.loadingFiles = 'succeeded';
      })
      .addCase(fetchOwnshare.fulfilled, (state, action) => {
        state.workspaceShare = action.payload as any;
      });
  },
});

export const fetchFiles = createAsyncThunk(
  'workspace/fetchFiles',
  async (arg, { getState }) => {
    const state = getState() as RootState;
    try {
      if (!state?.workspaces?.workspace?._id!) return [];
      const response = await getFileList(state?.workspaces?.workspace?._id!);
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
);
export const fetchApps = createAsyncThunk(
  'workspace/fetchApps',
  async (arg, { getState }) => {
    const state = getState() as RootState;
    try {
      const response = await applicationList(
        state?.workspaces?.workspace?._id!,
      );
      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
);
export const fetchWorkspaces = createAsyncThunk('workspace/fetch', async () => {
  try {
    const response = await listWorkspaces();
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
});
export const fetchOwnshare = createAsyncThunk(
  'workspace/fetchOwnShare',
  async (arg, { getState }) => {
    const state = getState() as RootState;
    try {
      if (!state?.workspaces?.workspace?._id!) return [];
      const response = await getWorkspaceShareById(
        state?.workspaces?.workspace?._id!,
      );
      return response;
    } catch (e) {
      console.error(e);
      return {};
    }
  },
);

export const { setWorkspace, addFile } = slice.actions;

export default slice.reducer;
