import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth';
import WorkspacesReducer from './workspaces';
import FileReducer from './file';
export const store = configureStore({
  reducer: {
    workspaces: WorkspacesReducer,
    auth: AuthReducer,
    file: FileReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
