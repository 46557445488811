import { request } from "../utils/request";
import { BackendResponse } from "./data";

export interface ConfluenceSpace {
    name: string;
    id: string;
    key: string;
}
export interface ConfluencePage {
  title: string;
  id: string;
}

export const getConfluencePages = async (spaceId: string) => {
  const res = await request<BackendResponse<ConfluencePage[]>>('/fileSource/confluence/pages?spaceId='+spaceId, {
    method: 'get',
  });
  return res;
};

export const getConfluenceSpaces = async () => {
    const res = await request<BackendResponse<ConfluenceSpace[]>>('/fileSource/confluence/spaces', {
      method: 'get',
    });
    return res;
  };
