import { request } from "../utils/request";
import { BackendResponse } from "./data";

const {REACT_APP_BACKEND_DATA} = process.env;

interface SyncWorkspaceModel {

}

const overrideRequest = {
  prefix: REACT_APP_BACKEND_DATA
}

export const syncWorkspce = async (workspaceId: string) => {
  const res = await request<BackendResponse<SyncWorkspaceModel[]>>('/ingestion/'+workspaceId, {
    method: 'post',
    ...overrideRequest
  });
  return res;
};

export const deleteWorkspce = async (workspaceId: string) => {
  const res = await request<BackendResponse<SyncWorkspaceModel[]>>('/ingestion/'+workspaceId, {
    method: 'delete',
    ...overrideRequest
  });
  return res;
};