// Fetch options with Authorization headers
const fetchOptions = (token: string): RequestInit => ({
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});
export interface Site {
  id: string;
  name: string;
  webUrl: string;
}

export interface Drive {
  id: string;
  name: string;
  webUrl: string;
}

export interface DriveItem {
  id: string;
  name: string;
  size: number;
  lastModifiedDateTime: string;
  lastModifiedBy: {
    user: {
      displayName: string;
    };
  };
  folder?: any;
  file?: any;
  webUrl: string;
}

// Folder Interface (exists when the item is a folder)
interface Folder {
  childCount: number;
}

// File Interface (exists when the item is a file)
interface File {
  mimeType: string;
  hash?: string;
}

// Function to fetch SharePoint sites
export async function fetchSites(token: string): Promise<Site[]> {
  const response = await fetch(
    'https://graph.microsoft.com/v1.0/sites?search=',
    fetchOptions(token),
  );
  if (!response.ok) throw new Error('Error fetching sites');

  const data = await response.json();
  return data.value;
}

// Function to fetch document libraries (drives) from a site
export async function fetchLibraries(
  token: string,
  siteId: string,
): Promise<Drive[]> {
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/sites/${siteId}/drives`,
    fetchOptions(token),
  );
  if (!response.ok) throw new Error('Error fetching document libraries');

  const data = await response.json();
  return data.value.map(
    (drive: any): Drive => ({
      id: drive.id,
      name: drive.name,
      webUrl: drive.webUrl,
    }),
  );
}

// Function to fetch items (files or folders) from a specific document library or folder
export async function fetchItems(
  token: string,
  driveId: string,
  parentId: string = 'root',
): Promise<DriveItem[]> {
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${parentId}/children`,
    fetchOptions(token),
  );

  if (!response.ok) {
    throw new Error('Error fetching items');
  }

  const data = await response.json();
  return data.value; // Return the array of items (files and folders)
}
