import { UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import { uploadFlow } from '../data/uploadFile';
import { WorkspaceModel } from '../data/workspace';
import { MessageInstance } from 'antd/lib/message/interface';
import { useDispatch } from 'react-redux';
import { addFile } from '../redux/workspaces';

const useFileUpload = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getUploadProps = (
    workspace: WorkspaceModel,
    messageApi: MessageInstance,
  ) => {
    const props: UploadProps = {
      name: 'file',
      multiple: true,
      customRequest: async ({ file, onProgress, onSuccess, onError }) => {
        setIsUploading(true);
        try {
          // Call uploadFlow and provide onProgress callback for status updates
          const uploadedFile = await uploadFlow(
            file as RcFile,
            workspace!._id,
            (percent) => {
              onProgress!({ percent });
            },
          );

          // Call onSuccess when upload completes successfully
          dispatch(addFile(uploadedFile));
          onSuccess!(null, file as any);
        } catch (error) {
          // Handle error with onError callback
          onError!(error as any);
        }
        setIsUploading(false);
      },
      onChange(info: any) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          messageApi.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          messageApi.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
    };
    return props;
  };
  return { getUploadProps, isUploading };
};

export default useFileUpload;
