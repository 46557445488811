import es from '@ant-design/pro-layout';
import { useSelector } from 'react-redux';
import { WorkspaceModel } from '../data/workspace';
import { RootState } from '../redux';
import { WORKSPACE_PERMISSION_LEVEL } from '../data/share';

const useWorkspace = () => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const { workspaceShare } = useSelector(
    (state: RootState) => state.workspaces,
  );

  const checkCurrentWorkspacePermissions = (
    permissionLevelRequired: WORKSPACE_PERMISSION_LEVEL,
  ) => {
    if (isAdmin) return true;

    return (
      workspaceShare?.permissionLevel &&
      workspaceShare?.permissionLevel >= permissionLevelRequired
    );
  };

  return { checkCurrentWorkspacePermissions };
};
export default useWorkspace;
