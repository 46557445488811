import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux';
import { localLogin, localLogout, setError } from '../../redux/auth';
import { request } from '../../utils/request';
import AiLayoutUnauthenticated from '../layout/AiLayoutUnauthenticated';
import LoginBox, { UserDataState } from './LoginBox';

import { Button, Result, message } from 'antd';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import authConfig from '../../config/authConfig';

interface AuthWrapperProps {
  children?: React.ReactNode;
}

let ORG = '';
export let TOKEN = '';

export const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  ...(authConfig as any),
});

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, error } = useSelector(
    (state: RootState) => state.auth,
  );
  const [userDataStage, setUserDataStage] = useState<UserDataState>();
  const [messageApi, contextHolder] = message.useMessage();

  // auth pages have a code query, but trigger different auth protocols (e.g. entraid)
  const isAuthPage = window.location.pathname === '/auth';

  function authorize() {
    userManager.signinRedirect({ state: 'a2123a67ff11413fa19217a9ea0fbad5' });
  }

  useEffect(() => {
    if (error) {
      setUserDataStage(undefined);
      // handle auth errors indicating a sign-off action (e.g expired tokens)
      if (error.triggerLogout) {
        console.log('Logout triggered from backend');
        // show error message
        messageApi.error(
          'An authentication error occured: ' +
            error.description +
            '. Please sign in again.',
        );
        dispatch(localLogout());
      }
      // show other errors -> handled in component (shoes error)
    }
  }, [error]);

  const handleUserInfo = async (token: string) => {
    // fetch user info from zitadel
    try {
      const res = await fetch(authConfig.userinfo_endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userInfo = await res.json();
      if (userInfo.error) {
        console.log('userInfo error', userInfo);
        //userManager.signoutSilent();
        dispatch(localLogout());
      } else {
        // fetch available brands
        setUserDataStage(UserDataState.BRANDS);
        setUserDataStage(UserDataState.USER_INFORMATION);
        dispatch(localLogin(userInfo as any));
      }
    } catch (e) {
      dispatch(localLogout());
    }
  };

  useEffect(() => {
    if (isAuthPage) return;
    if (window.location.search.indexOf('code=') >= 0) {
      userManager
        .signinRedirectCallback()
        .then((user) => {
          window.location.href = '/';
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userManager
        .getUser()
        .then((user) => {
          if (user) {
            TOKEN = user?.access_token;
            console.log('userManager ok', user);
            if (user?.expired) {
              try {
                userManager
                  .signinSilent()
                  .then((user) => {
                    if (user?.access_token) {
                      TOKEN = user?.access_token;
                      handleUserInfo(TOKEN);
                    } else {
                      dispatch(localLogout());
                    }
                  })
                  .catch((e) => {
                    console.error(e);
                    dispatch(localLogout());
                  });
              } catch (e) {
                console.error(e);
                dispatch(localLogout());
              }
            } else {
              handleUserInfo(TOKEN);
            }
          } else {
            dispatch(localLogout());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(localLogout());
        });
    }
  }, [userManager]);

  useEffect(() => {
    request.use(async (ctx, next) => {
      ctx.req.options.headers = {
        Authorization: 'Bearer ' + TOKEN,
        ...ctx.req.options.headers,
      };
      await next();
      if (
        ['AuthorizationError', 'PermissionDeniedError'].includes(ctx.res.type)
      ) {
        dispatch(setError(ctx.res));
      }
    });
  }, [0]);

  if ((!isAuthenticated || error) && !isAuthPage) {
    if (error && !error.triggerLogout) {
      return (
        <AiLayoutUnauthenticated>
          <Result
            status="403"
            title="Authentication error"
            subTitle={
              'An authentication error occurred: > ' +
              error.description +
              " < Please reload the page. If this doesn't help, sign out and try again."
            }
            extra={
              <Button
                type="primary"
                onClick={() => {
                  dispatch(localLogout());
                }}
              >
                Sign out
              </Button>
            }
          />
        </AiLayoutUnauthenticated>
      );
    }
    return (
      <AiLayoutUnauthenticated>
        <LoginBox handleLogin={authorize} userDataStage={userDataStage} />
        {contextHolder}
      </AiLayoutUnauthenticated>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
