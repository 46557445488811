const authConfig = {
  authority: process.env.REACT_APP_ZITADEL_ISSUER ?? '',
  client_id: process.env.REACT_APP_ZITADEL_CLIENT_ID ?? '',
  redirect_uri: window.location.origin,
  automaticSilentRenew: true,
  response_type: 'code',
  scope: 'openid profile email offline_access urn:zitadel:iam:user:resourceowner',
  post_logout_redirect_uri: window.location.origin,
  userinfo_endpoint: process.env.REACT_APP_ZITADEL_USERINFO ?? '',
  response_mode: 'query',
  code_challenge_method: 'S256',
};

export default authConfig;
