import { request_python, request } from '../utils/request';
import { BackendResponse } from './data';
import { RcFile } from 'antd/es/upload/interface';

interface PresignedPostResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export interface RegisterFileResponse {
  _id: string;
  filename: string;
  s3Key: string;
  size: number;
  mimeType: string;
  token: string;
  isPublic: boolean;
  isSystemGenerated: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const getFileList = async (workspaceId: string) => {
  const res = await request<RegisterFileResponse[]>(
    '/api/v1/workspace/' + workspaceId + '/files',
    {
      method: 'get',
      ...request_python,
    },
  );
  return res;
};

export const fileDelete = async (
  fileId: string,
  params: { fileSourceId?: string; workspaceId?: string },
) => {
  const queryString = new URLSearchParams(params).toString();

  const res = await request<any>(`/api/v1/files/${fileId}?${queryString}`, {
    method: 'delete',
    ...request_python,
  });

  return res;
};

export const fileDownloadLink = async (fileId: string) => {
  const res = await request<PresignedPostResponse>(
    '/api/v1/files/presigned/' + fileId,
    {
      method: 'get',
      ...request_python,
    },
  );

  return res;
};

const getPresignedPost = async (workspaceId: string, filename: string) => {
  const res = await request<PresignedPostResponse>('/api/v1/files/presigned', {
    method: 'post',
    data: {
      workspaceId: workspaceId,
      filename: filename,
    },
    ...request_python,
  });

  return res;
};

export const registerFile = async (
  workspaceId: string,
  filename: string,
  s3Key: string,
  fileSize: number,
  mimeType: string,
) => {
  const res = await request<RegisterFileResponse>('/api/v1/files/register', {
    method: 'post',
    data: {
      workspaceId: workspaceId,
      filename: filename,
      fileSize: fileSize,
      s3Key: s3Key,
      mimeType: mimeType,
    },
    ...request_python,
  });

  return res;
};
export const uploadFlow = (
  file: RcFile,
  workspaceId: string,
  onProgress: (percent: number) => void,
) => {
  return new Promise<RegisterFileResponse>(async (resolve, reject) => {
    try {
      const presignedProgress = 5;
      const registerProgress = 5;

      // Allocate 5% progress for presigned URL generation
      onProgress(presignedProgress);
      const data: PresignedPostResponse = await getPresignedPost(
        workspaceId,
        file.name,
      );

      // Create FormData and append presigned S3 fields
      const formData = new FormData();
      for (const key in data.fields) {
        formData.append(key, data.fields[key]); // Appending S3 fields from presigned post response
      }
      formData.append('file', file); // Append the file

      // Track upload progress
      const totalBytes = file.size;
      let bytesUploaded = 0;

      // Use XMLHttpRequest for progress tracking and FormData upload
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          bytesUploaded = event.loaded;
          const percent = presignedProgress + (bytesUploaded / totalBytes) * 90; // Allocate 90% for the actual upload progress
          onProgress(percent); // Trigger the progress callback
        }
      };

      xhr.onload = async () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // Allocate final 5% for registerFile after upload completion
          onProgress(100 - registerProgress);
          const register = await registerFile(
            workspaceId,
            file.name,
            data.fields.key,
            file.size,
            file.type,
          );
          onProgress(100); // Complete the progress at 100%
          resolve(register); // Resolve the promise on success
        } else {
          reject(new Error('File upload failed')); // Reject the promise on failure
        }
      };

      xhr.onerror = () => {
        reject(new Error('File upload failed')); // Properly reject the promise on error
      };

      // Open the request and send the FormData
      xhr.open('POST', data.url);
      xhr.send(formData);
    } catch (error) {
      reject(error); // Catch any errors from presigned URL or registerFile
    }
  });
};
