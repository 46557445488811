import {
  FileSyncOutlined,
  PlusOutlined,
  SettingOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, MenuProps, message, Spin } from 'antd';
import modal from 'antd/es/modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WorkspaceAddModal, {
  WorkspaceEditModalSteps,
} from '../../../components/workspace/WorkspaceEditModal';
import { deleteWorkspce, syncWorkspce } from '../../../data/dataIngestion';
import { deleteWorkspace, WorkspaceModel } from '../../../data/workspace';
import { AppDispatch, RootState } from '../../../redux';
import { fetchWorkspaces } from '../../../redux/workspaces';
import './styles.css';
import useWorkspace from '../../../utils/useWorkspace';
import { WORKSPACE_PERMISSION_LEVEL } from '../../../data/share';

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: 'sync',
    label: 'Sync files',
    icon: <SyncOutlined />,
  },
  {
    type: 'divider',
    requiresAdminPermissions: true,
  },
  {
    key: WorkspaceEditModalSteps.FILESOURCES,
    label: 'File sources',
    icon: <FileSyncOutlined />,
    requiresAdminPermissions: true,
  },
  {
    key: WorkspaceEditModalSteps.SHARE,
    label: 'Access',
    icon: <UserOutlined />,
    requiresAdminPermissions: true,
  },
  {
    key: WorkspaceEditModalSteps.SETTINGS,
    label: 'Settings',
    icon: <UnorderedListOutlined />,
    requiresAdminPermissions: true,
  },
  {
    type: 'divider',
    requiresAdminPermissions: true,
  },
  {
    key: 'delete',
    danger: true,
    label: 'Delete',
    requiresAdminPermissions: true,
  },
];

const WorkspaceMenu: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalFormOpen, setIsModalFormOpen] = useState<boolean>(false);
  const [dropdownOpenWorkspace, setDropdownOpenWorkspace] = useState<string>();
  const [modalFormStep, setModalFormStep] = useState<WorkspaceEditModalSteps>();
  const [modalFormItem, setModalFormItem] = useState<WorkspaceModel>();
  const { workspaceList, workspace, loading } = useSelector(
    (state: RootState) => state.workspaces,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const isLoadingFinal = loading === 'pending' || isLoading;
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { checkCurrentWorkspacePermissions } = useWorkspace();

  const remove = async (current: WorkspaceModel) => {
    modal.confirm({
      title: 'Delete workspace ' + current?.name,
      content:
        'Are you sure that you want to delete the workspace ' +
        current?.name +
        ' and all associated files? This can not be undone.',
      async onOk() {
        setLoading(true);
        await deleteWorkspace(current!._id);
        messageApi.success(
          'Workspace ' + current?.name + ' was successfully deleted',
        );
        setLoading(false);
        updateWorkspaceList();

        // fire and forget as we don't need to wait or care about result
        deleteWorkspce(current!._id);
      },
    });
  };

  const updateWorkspaceList = async () => {
    await dispatch(fetchWorkspaces()).unwrap();
  };

  useEffect(() => {
    updateWorkspaceList();
  }, []);

  const onWorkspaceAction = (workspace: WorkspaceModel, actionKey: string) => {
    if (actionKey === 'delete') remove(workspace);
    else if (actionKey === 'sync') sync(workspace);
    else {
      setModalFormStep(Number(actionKey) as unknown as WorkspaceEditModalSteps);
      setModalFormItem(workspace);
      setIsModalFormOpen(true);
    }
  };

  const sync = async (workspace: WorkspaceModel) => {
    const load = messageApi.loading(
      'Ingesting files for workspace ' + workspace.name,
      0,
    );
    try {
      const res = await syncWorkspce(workspace?._id);
      // TODO: need proper response to check status
      load();
      messageApi.success(
        'Successfully ingested files for workspace ' + workspace.name,
      );
    } catch (e) {
      load();
      messageApi.error(
        'Failed ingesting files for workspace ' + workspace.name,
      );
    }
  };

  return (
    <>
      <Sider theme="light" width={200}>
        <Spin spinning={isLoadingFinal}>
          <Menu
            mode="vertical"
            onClick={(e) => {
              if (e.key !== '1') navigate('/workspace/' + e.key);
            }}
            selectedKeys={workspace ? [workspace._id] : []}
            style={{ overflowY: 'auto', maxHeight: '100vh' }}
          >
            <Menu.Item
              key="1"
              icon={<PlusOutlined />}
              onClick={(e) => {
                setModalFormStep(undefined);
                setIsModalFormOpen(true);
                setModalFormItem(undefined);
                e.domEvent.stopPropagation();
              }}
            >
              Add Workspace
            </Menu.Item>
            <Menu.Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
            {workspaceList?.map((i) => (
              <Menu.Item key={i._id}>
                <div
                  className={
                    'workspaceRow ' +
                    (dropdownOpenWorkspace === i._id
                      ? 'workspaceDrowndownActive'
                      : 'workspaceDrowndownInactive')
                  }
                >
                  <div className="workspaceSettings">
                    <Dropdown
                      menu={{
                        // @ts-ignore
                        items: items.filter(
                          (i) =>
                            !i.requiresAdminPermissions ||
                            checkCurrentWorkspacePermissions(
                              WORKSPACE_PERMISSION_LEVEL.MANAGE,
                            ),
                        ),
                        onClick: (handler) => {
                          handler.domEvent.stopPropagation();
                          onWorkspaceAction(i, handler.key);
                        },
                      }}
                      onOpenChange={(open) => {
                        setDropdownOpenWorkspace(open ? i._id : undefined);
                      }}
                    >
                      <a>
                        <Button style={{ marginRight: -12 }}>
                          <SettingOutlined />
                        </Button>
                      </a>
                    </Dropdown>
                  </div>

                  {i.name}
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </Spin>
      </Sider>
      <WorkspaceAddModal
        isOpen={isModalFormOpen}
        setIsOpen={setIsModalFormOpen}
        current={modalFormItem}
        enforceStep={modalFormStep}
      />
      {contextHolder}
    </>
  );
};

export default WorkspaceMenu;
