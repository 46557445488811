import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthWrapper from './components/AuthWrapper';
import AiLayout from './components/layout/OmmaxLayout';
import { theme } from './config/design';
import ChatMain from './pages/chat';
import FlowiseProxy from './pages/flowiseProxy';
import { store } from './redux';
import GraphAPIAuthCallback from './pages/GraphAPIAuthCallback';

const App: React.FC = () => {
  return (
    <ConfigProvider theme={theme} locale={enUS}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthWrapper>
            <AiLayout>
              <Routes>
                <Route path="/" element={<ChatMain />} />
                <Route path="/auth" element={<GraphAPIAuthCallback />} />
                <Route path="/workspace/:workspaceId" element={<ChatMain />} />
                <Route
                  path="/flowiseProxy/:pageType/:flowId"
                  element={<FlowiseProxy />}
                />
                <Route path="/flowiseProxy" element={<FlowiseProxy />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </AiLayout>
          </AuthWrapper>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
