import {
  FileSyncOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Form, message, Modal, Space, Spin, Steps } from 'antd';
import modal from 'antd/es/modal';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { syncWorkspce } from '../../data/dataIngestion';
import {
  createWorkspace,
  deleteWorkspace,
  getWorkspaceById,
  updateWorkspace,
  WorkspaceModel,
} from '../../data/workspace';
import { AppDispatch } from '../../redux';
import { fetchWorkspaces } from '../../redux/workspaces';
import WorkspaceDetails from './WorkspaceDetails';
import WorkspaceFiles from './WorkspaceFiles';
import WorkspaceShare from './WorkspaceShare';

interface WorkspaceEditModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  current?: WorkspaceModel;
  enforceStep?: WorkspaceEditModalSteps;
}
export interface WorkspaceEditChildModalProps {
  isLoading: boolean;
  listOnly?: boolean;
  setLoading: (loading: boolean) => void;
  current?: WorkspaceModel;
  form: any;
}

const WORKSPACE_INITIAL_VALUES = {
  expiryDate: dayjs().add(60, 'day'),
  fileSources: [],
  shares: [],
};

export enum WorkspaceEditModalSteps {
  SETTINGS = 1,
  SHARE = 2,
  FILESOURCES = 3,
}

const FINAL_STEP = WorkspaceEditModalSteps.FILESOURCES;

export const formItemLayoutFull = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const WorkspaceAddModal: React.FC<WorkspaceEditModalProps> = (params) => {
  const { isOpen, setIsOpen, current, enforceStep } = params;
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [step, setStep] = useState<WorkspaceEditModalSteps>(
    WorkspaceEditModalSteps.SETTINGS,
  );
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  // on close, reset values
  useEffect(() => {
    if (!isOpen) {
      setStep(WorkspaceEditModalSteps.SETTINGS);
      form.resetFields();

      form.setFieldsValue(WORKSPACE_INITIAL_VALUES);
      setLoading(false);
    }
  }, [isOpen]);

  // if step is enforced, navigate to step
  useEffect(() => {
    if (enforceStep && isOpen) {
      console.log('st step', enforceStep);
      setStep(enforceStep);
    }
  }, [enforceStep, isOpen]);

  // if workspace given, always load latest variant to ensure no duplicate updates
  const loadWorkspace = async () => {
    setLoading(true);
    const data = await getWorkspaceById(current!._id);
    form.setFieldsValue({
      ...data.data,
      expiryDate: dayjs(data.data.expiryDate),
    });
    setLoading(false);
  };

  useEffect(() => {
    if (current && isOpen) {
      loadWorkspace();
    }
  }, [current, isOpen]);

  const remove = async () => {
    const confirmed = await modal.confirm({
      title: 'Delete workspace ' + current?.name,
      content:
        'Are you sure that you want to delete the workspace ' +
        current?.name +
        ' and all associated files? This can not be undone.',
    });
    if (confirmed) {
      setLoading(true);
      await deleteWorkspace(current!._id);
      messageApi.success(
        'Workspace ' + current?.name + ' was successfully deleted',
      );
      setLoading(false);
      setIsOpen(false);
      await dispatch(fetchWorkspaces()).unwrap();
    }
  };

  const save = async () => {
    const values = await form.getFieldsValue(true);

    setLoading(true);
    if (!current) {
      const res = await createWorkspace(values);
      console.log(res);

      try {
        const res2 = await syncWorkspce(res?.data?._id);
        // TODO: need proper response to check status
        messageApi.success(
          'Workspace ' +
            values.name +
            ' was successfully created and files ingested',
        );
      } catch (e) {
        console.error(e);
        messageApi.success(
          'Workspace ' +
            values.name +
            ' was successfully created but file ingestion failed',
        );
      }
    } else {
      const res = await updateWorkspace(current!._id, {
        ...values,
        _id: undefined,
      });

      if (enforceStep === WorkspaceEditModalSteps.FILESOURCES) {
        try {
          const res2 = await syncWorkspce(res?.data?._id);
        } catch (e) {
          console.error(e);
        }
      }

      console.log(res);
    }
    setLoading(false);
    setIsOpen(false);
    await dispatch(fetchWorkspaces()).unwrap();
  };

  const next = () => {
    if (step !== FINAL_STEP) changeStep(step + 1);
    else save();
  };
  const Footer = () => {
    return (
      <Space>
        {!!current && (
          <Button onClick={() => remove()} danger>
            Delete
          </Button>
        )}
        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        {step !== 1 && !enforceStep && (
          <Button onClick={() => changeStep(step - 1)}>Back</Button>
        )}
        {step !== FINAL_STEP && !enforceStep && (
          <Button onClick={() => changeStep(step + 1)} type="primary">
            Next
          </Button>
        )}
        {(step === FINAL_STEP || enforceStep) && (
          <Button onClick={() => save()} type="primary">
            Save
          </Button>
        )}
      </Space>
    );
  };

  const childProps = {
    isLoading,
    setLoading,
    form,
    current,
  };

  const changeStep = async (step: number) => {
    try {
      const fieldsValue = await form.validateFields();
      console.log(fieldsValue);
      setStep(step);
    } catch (e) {
      // validation failed and shown to user
    }
  };

  return (
    <>
      <Modal
        title={current ? 'Edit Workspace' : 'Create Workspace'}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        confirmLoading={isLoading}
        footer={<Footer />}
        width={1024}
      >
        <Spin spinning={isLoading}>
          {!enforceStep && (
            <Steps
              onChange={(s) => changeStep(s + 1)}
              current={step - 1}
              items={[
                {
                  title: 'Details',
                  icon: <UnorderedListOutlined />,
                  description: 'Detailed settings ',
                },
                {
                  title: 'Access',
                  icon: <UserOutlined />,
                  description: 'Access permissions',
                },
                {
                  title: 'Files',
                  icon: <FileSyncOutlined />,
                  description: 'Synchronize files',
                },
              ]}
            />
          )}
          <Form
            form={form}
            name="workspace"
            {...formItemLayout}
            onFinish={save}
            style={{ marginTop: 15 }}
            preserve={true}
            initialValues={WORKSPACE_INITIAL_VALUES}
            onSubmitCapture={() => next()}
          >
            {step === WorkspaceEditModalSteps.SETTINGS && (
              <WorkspaceDetails {...childProps} />
            )}
            {step === WorkspaceEditModalSteps.SHARE && (
              <WorkspaceShare {...childProps} />
            )}
            {step === WorkspaceEditModalSteps.FILESOURCES && (
              <WorkspaceFiles {...childProps} />
            )}
          </Form>
        </Spin>
      </Modal>
      {contextHolder}
    </>
  );
};

export default WorkspaceAddModal;
