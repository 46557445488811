import { request, request_python } from '../utils/request';
import { BackendResponse } from './data';

export enum WORKSPACE_PERMISSION_LEVEL {
  READ = 1,
  MANAGE = 8,
  OWN = 9,
}

export interface ShareModel {
  userName: string;
  userEmail: string;
  permissionLevel: WORKSPACE_PERMISSION_LEVEL;
  userId: string;
  user?: ShareUserModel;
}

export interface ShareUserModel {
  id: string;
  displayName: string;
}

export const getShareUserList = async (query: string) => {
  const res = await request<ShareUserModel[]>(
    '/api/v1/sharepoint/users?$search="displayName:' + query + '"',
    {
      method: 'get',
      ...request_python,
    },
  );
  return res;
};
