import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';

const config = {
  authority:
    'https://login.microsoftonline.com/' +
    process.env.REACT_APP_SHAREPOINT_TENNANT_ID +
    '/v2.0',
  client_id: process.env.REACT_APP_SHAREPOINT_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SHAREPOINT_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile email offline_access',
  code_challenge_method: 'S256',
  userStore: new WebStorageStateStore({ store: window.localStorage }), // Store user data in localStorage
};

export const userManager = new UserManager(config as any);

const useGraphAPI = () => {
  const callbackHandler = async () => {
    const res = await userManager.signinPopupCallback();
  };
  const getBearer = async () => {
    const res = await userManager.signinPopup();
    return res;
  };

  return { getBearer, callbackHandler };
};

export default useGraphAPI;
