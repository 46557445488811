import { AutoComplete, Input, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ConfluenceSpace, getConfluenceSpaces } from '../../data/confluenceSource';

const ConfluenceSpaceSearch: React.FC<any> = (props) => {
  const { placeholder = 'Type to search confluence space...', ...otherProps } =
    props;
  const [spaceList, setSpaceList] = useState<ConfluenceSpace[]>([]);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    const list = await getConfluenceSpaces();
    setSpaceList(list.data);
    setOptionsData(undefined, list.data);
    setLoading(false);
  };

  const setOptionsData = (
    search: string | undefined,
    list: ConfluenceSpace[],
  ) => {
    const mappedList = list
      ?.filter(
        (i) => !search || i.name.toLowerCase().includes(search.toLowerCase()),
      )
      .map((i) => ({ value: i.name, option: i.id }));
    setOptions(mappedList || []);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <AutoComplete
        options={options}
        size="large"
        onSearch={(val) => setOptionsData(val, spaceList)}
        {...otherProps}
      >
        <Input size="large" placeholder={placeholder} allowClear />
      </AutoComplete>
    </Spin>
  );
};

export default ConfluenceSpaceSearch;
