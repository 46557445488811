
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TOKEN } from '../../components/AuthWrapper';


const flowiseUrl: string = process.env.REACT_APP_FLOWISE_URL || "https://proxy-flowise-auth.server.delphi-dialogue.com/"

const FlowiseProxy: React.FC = () => {
    const { pageType, flowId } = useParams();

    useEffect(() => {
        if (pageType && flowId) window?.open(flowiseUrl+pageType+"/"+flowId+"?jwt="+TOKEN, '_blank')?.focus();
        else window?.open(flowiseUrl+"?jwt="+TOKEN, '_blank')?.focus();
        window?.history?.back();
    }, [])

    return (
        <Spin tip="Please wait while we redirect you to Flowise" spinning />
    );
}

export default FlowiseProxy;