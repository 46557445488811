import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFileState {
  isFileDragging: boolean;
}

const initialState: IFileState = {
  isFileDragging: false,
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setDragging: (state, action: PayloadAction<any>) => {
      state.isFileDragging = action.payload;
    },
  },
});

export const { setDragging } = slice.actions;

export default slice.reducer;
