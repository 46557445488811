import { request } from '../utils/request';
import { BackendResponse } from './data';
import { FileSource } from './fileSource';
import { ShareModel } from './share';

export interface WorkspaceModel {
  _id: string;
  name: string;
  isPublic: boolean;
  expiryDate: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  fileSources: Partial<FileSource[]>;
  advanced?: {
    maximumFileCount?: number;
    maximumFileSize?: number;
  };
}

export const listWorkspaces = async () => {
  const res = await request<BackendResponse<WorkspaceModel[]>>(
    '/workspace/list',
    {
      method: 'get',
    },
  );
  return res;
};

export const shareWorkspace = async (workspaceId: string, data: any) => {
  const res = await request<any>(`/workspace/${workspaceId}/share`, {
    method: 'post',
    data,
  });
  return res;
};

export const removeShareWorkspace = async (workspaceId: string) => {
  const res = await request<any>(`/workspace/${workspaceId}/share`, {
    method: 'delete',
  });
  return res;
};

export const updateWorkspace = async (workspaceId: string, data: any) => {
  const res = await request<BackendResponse<WorkspaceModel>>(
    `/workspace/${workspaceId}`,
    {
      method: 'patch',
      data,
    },
  );
  return res;
};

export const getWorkspaceShareById = async (workspaceId: string) => {
  const res = await request<BackendResponse<ShareModel>>(
    `/workspace/${workspaceId}/share`,
    {
      method: 'get',
    },
  );
  return res;
};

export const getWorkspaceById = async (workspaceId: string) => {
  const res = await request<BackendResponse<WorkspaceModel>>(
    `/workspace/${workspaceId}`,
    {
      method: 'get',
    },
  );
  return res;
};

export const createWorkspace = async (data: Partial<WorkspaceModel>) => {
  const res = await request<BackendResponse<WorkspaceModel>>('/workspace', {
    method: 'put',
    data,
  });
  return res;
};

export const deleteWorkspace = async (workspaceId: string) => {
  const res = await request<any>(`/workspace/${workspaceId}`, {
    method: 'delete',
  });
  return res;
};
