import { Button, Layout, Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { customization } from '../../config/customization';

const { Content, Footer } = Layout;

/*
 * Layout
 * General layout component
 * Handles things such as header and footer; defines content window
 */

interface AiLayoutProps {
  children?: React.ReactNode;
}

const AiLayoutUnauthenticated: React.FC<AiLayoutProps> = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>
        <Layout>{children}</Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Space wrap>
          <Button type="link" href="https://www.ommax-digital.com/en/imprint/">
            Imprint
          </Button>
          <Button type="link">Privacy Policy</Button>
          {process.env.REACT_APP_ENV && <Tooltip title={"Environment: "+process.env.REACT_APP_ENV}><Tag color="cyan">{process.env.REACT_APP_ENV}</Tag></Tooltip>}
        </Space>
        <br />
        {customization.copyright}
      </Footer>
    </Layout>
  );
};

export default AiLayoutUnauthenticated;
