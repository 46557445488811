import { DatePicker, Form, Input, Switch } from 'antd';
import dayjs from 'dayjs';
import {
  WorkspaceEditChildModalProps
} from './WorkspaceEditModal';

const WorkspaceDetails: React.FC<WorkspaceEditChildModalProps> = (params) => {
  const form = Form.useFormInstance();

  return (
    <>
      <Form.Item
        name="name"
        label="Name"
        extra="Public visible name that describes this workspace"
        rules={[{ required: true, message: 'Please choose a workspace name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="expiryDate"
        label="Expiry date"
        extra="Workspace and data will be automatically deleted after expiry"
      >
        <DatePicker allowClear maxDate={dayjs().add(6, "months")} minDate={dayjs().add(1, "days")}  />
      </Form.Item>
      <Form.Item
        name="isPublic"
        valuePropName="checked"
        hidden
      >
        <Switch />
      </Form.Item>
    </>
  );
};

export default WorkspaceDetails;
