import {
  Button,
  Col,
  Drawer,
  message,
  Row,
  Spin,
  Tabs,
  TabsProps,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFiles } from '../../../redux/workspaces';
import { AppDispatch, RootState } from '../../../redux';
import { ProList } from '@ant-design/pro-components';
import {
  fileDelete,
  fileDownloadLink,
  RegisterFileResponse,
} from '../../../data/uploadFile';
import Alert from 'antd/lib/alert/Alert';
import { mimeToFileIcon } from '../../../utils/fileIcon';
import { FaFile } from 'react-icons/fa';
import { InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import modal from 'antd/es/modal';
import useFileUpload from '../../../utils/useFileUpload';
import Dragger from 'antd/es/upload/Dragger';
import FileMenu from '../../../components/FileMenu';

interface FileDrawerProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const FileDrawer: React.FC<FileDrawerProps> = (props) => {
  const { isVisible, setIsVisible } = props;
  const { workspace, loadingFiles, workspaceFiles } = useSelector(
    (state: RootState) => state.workspaces,
  );
  const dispatch = useDispatch<AppDispatch>();

  const updateFileList = () => dispatch(fetchFiles()).unwrap();

  useEffect(() => {
    if (isVisible) {
      updateFileList();
    }
  }, [isVisible]);

  return (
    <Drawer
      title="Workspace files"
      onClose={() => setIsVisible(false)}
      open={isVisible}
      width={600}
      extra={
        <Button onClick={() => updateFileList()}>
          <ReloadOutlined /> Refresh
        </Button>
      }
    >
      <FileMenu
        workspace={workspace!}
        files={workspaceFiles || []}
        updateFileList={updateFileList}
        isLoadingFiles={loadingFiles === 'pending'}
        isVisible={isVisible}
      />
    </Drawer>
  );
};

export default FileDrawer;
