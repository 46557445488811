import { Button, Form, Input, Modal, Result, Spin, message } from 'antd';
import { Key, useEffect, useRef, useState } from 'react';
import { FileSource } from '../../data/fileSource';
import useGraphAPI from '../../utils/useGraphAPI';
import { User } from 'oidc-client-ts';
import {
  Site,
  Drive,
  fetchItems,
  fetchLibraries,
  fetchSites,
  DriveItem,
} from '../../data/sharepoint';
import { ProList } from '@ant-design/pro-components';
import { ArrowLeftOutlined } from '@ant-design/icons'; // Import the back icon
import { mimeToFileIcon } from '../../utils/fileIcon';
import { FaFile } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const SIZE_THRESHOLD = 1000000000; // 1 GB in bytes
const COUNT_THRESHOLD = 100; // Max item count

interface FileSourceConfluenceModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  current?: FileSource;
  updateFileSource: (source: Partial<FileSource>) => void;
}

export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const FileSourceSharepointModal: React.FC<FileSourceConfluenceModalProps> = (
  params,
) => {
  const { isOpen, setIsOpen, current, updateFileSource } = params;
  const [authFailed, setAuthFailed] = useState<string>();
  const [graphAuth, setGraphAuth] = useState<User>();
  const [graphAuthInProgress, setGraphAuthInProgress] =
    useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [currentLevel, setCurrentLevel] = useState<
    'sites' | 'drives' | 'items'
  >('sites');
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [selectedDrive, setSelectedDrive] = useState<Drive | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<DriveItem | null>(null);
  const [selectedFolderParent, setSelectedFolderParent] =
    useState<DriveItem | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Add search state

  const searchInputRef = useRef<any>(null); // Reference to search input

  const { getBearer } = useGraphAPI();
  const [messageApi, contextHolder] = message.useMessage();
  const { workspace } = useSelector((state: RootState) => state.workspaces);

  const token = graphAuth?.access_token;

  const [form] = Form.useForm();

  // Set form fields when current changes
  useEffect(() => {
    setSelectedRowKeys(current?.sharepointItems || []);
  }, [current]);

  // Reset form fields when modal is closed
  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  const authenticate = async () => {
    setGraphAuthInProgress(true);
    try {
      const token = await getBearer();
      if (token) setGraphAuth(token);
    } catch (e) {
      setAuthFailed(e as string);
    }
    setGraphAuthInProgress(false);
  };

  const save = async () => {
    handleSelectionChange();
    /*
    const fieldsValue = await form.validateFields();
    await updateFileSource({
      type: 's3',
      s3BucketName: fieldsValue.bucket,
      s3Folder: fieldsValue.folder,
      humanReadableName: fieldsValue.bucket + '/' + fieldsValue.folder,
    });
    setIsOpen(false);*/
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: Key[]) => {
      setSelectedRowKeys(keys);
    },
  };

  async function fetchSitesData() {
    setLoading(true); // Show spinner
    try {
      const sites = await fetchSites(token!);
      setDataSource(sites);
    } catch (error) {
      message.error('Error fetching sites');
    } finally {
      setLoading(false); // Hide spinner
    }
  }

  async function fetchDrivesData() {
    setLoading(true); // Show spinner
    try {
      const drives = await fetchLibraries(token!, selectedSite!.id);
      setDataSource(drives);
    } catch (error) {
      message.error('Error fetching drives');
    } finally {
      setLoading(false); // Hide spinner
    }
  }

  async function fetchDriveItems(driveId: string, folderId: string = 'root') {
    setLoading(true); // Show spinner
    try {
      const items = await fetchItems(token!, driveId, folderId);
      setDataSource(items);
    } catch (error) {
      message.error('Error fetching items');
    } finally {
      setLoading(false); // Hide spinner
    }
  }

  // Fetch sites on token or current level change
  useEffect(() => {
    if (currentLevel === 'sites' && token) {
      fetchSitesData();
    }
  }, [currentLevel, token]);

  // Fetch drives on site or current level change
  useEffect(() => {
    if (currentLevel === 'drives' && selectedSite && token) {
      fetchDrivesData();
    }
  }, [currentLevel, selectedSite, token]);

  // Fetch drive items on drive, folder, or current level change
  useEffect(() => {
    if (currentLevel === 'items' && selectedDrive && token) {
      fetchDriveItems(
        selectedDrive.id,
        selectedFolder ? selectedFolder.id : 'root',
      );
    }
  }, [currentLevel, selectedDrive, selectedFolder, token]);

  // Handle navigation to drives
  const handleSiteClick = (site: Site) => {
    setSelectedSite(site);
    setCurrentLevel('drives');
    setSelectedRowKeys([]);
  };

  // Handle navigation to items
  const handleDriveClick = (drive: Drive) => {
    setSelectedDrive(drive);
    setCurrentLevel('items');
    setSelectedRowKeys([]);
  };

  // Handle folder click to navigate into folder contents
  const handleFolderClick = (folder: DriveItem) => {
    setSelectedFolderParent(selectedFolder);
    setSelectedFolder(folder);
    setSelectedRowKeys([]);
  };

  // Handle list item click
  const handleListItemClick = (item: any) => {
    setSearchTerm('');
    if (item.isParentFolder) {
      if (selectedFolder && selectedFolderParent) {
        setSelectedFolder(selectedFolderParent);
      } else if (selectedFolder) {
        setSelectedFolder(null);
      } else if (currentLevel === 'items') {
        setCurrentLevel('drives');
      } else if (currentLevel === 'drives') {
        setCurrentLevel('sites');
      }
    } else if (currentLevel === 'sites') {
      handleSiteClick(item);
    } else if (currentLevel === 'drives') {
      handleDriveClick(item);
    } else if (currentLevel === 'items' && item.folder) {
      handleFolderClick(item);
    }
  };

  // Filter data based on search term
  const filteredDataSource = dataSource.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Handle item selection and validation
  const handleSelectionChange = () => {
    const selectedItems = filteredDataSource.filter((item) =>
      selectedRowKeys.includes(item.id),
    );
    const totalSize = selectedItems.reduce(
      (acc, item) => acc + (item.size || 0),
      0,
    );
    const totalCount = selectedItems.length;

    if (totalSize > (workspace?.advanced?.maximumFileSize || SIZE_THRESHOLD)) {
      messageApi.error(
        `Combined file ${(totalSize / 1e6).toFixed(2)} MB size exceeds the maximum threshold of 1 GB`,
      );
    } else if (
      totalCount > (workspace?.advanced?.maximumFileCount || COUNT_THRESHOLD)
    ) {
      messageApi.error(
        `Combined item count ${totalCount} exceeds the maximum threshold of 100 items`,
      );
    } else {
      let humanReadableName = '';

      if (totalCount <= 2) {
        humanReadableName = selectedItems.map((item) => item.name).join(', ');
      } else {
        const folderName = selectedFolder ? selectedFolder.name : 'root';
        humanReadableName = `${totalCount} files in ${folderName}`;
      }

      messageApi.success(
        `Selected ${totalCount} with total size of ${(totalSize / 1e6).toFixed(2)} MB`,
      );

      updateFileSource({
        type: 'sharepoint',
        humanReadableName: humanReadableName, // Use the constructed name
        sharepointItems: selectedRowKeys as any,
        sharepointSite: selectedSite!.id,
        sharepointDrive: selectedDrive!.id,
        graphApi: {
          accessToken: graphAuth?.access_token,
          refreshToken: graphAuth?.refresh_token,
        },
      });
      setIsOpen(false);
    }
  };

  // Add keydown event listener for Ctrl+F
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'f') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Render Back Button based on current level
  const renderBackButton = () => {
    if (currentLevel === 'items' && selectedFolder) {
      return (
        <Button
          icon={<ArrowLeftOutlined />}
          style={{ marginBottom: 16 }}
          onClick={() => setSelectedFolder(null)}
        >
          Back to Parent Folder
        </Button>
      );
    } else if (currentLevel === 'items' && !selectedFolder) {
      return (
        <Button
          icon={<ArrowLeftOutlined />}
          style={{ marginBottom: 16 }}
          onClick={() => setCurrentLevel('drives')}
        >
          Back to Drive
        </Button>
      );
    } else if (currentLevel === 'drives') {
      return (
        <Button
          icon={<ArrowLeftOutlined />}
          style={{ marginBottom: 16 }}
          onClick={() => setCurrentLevel('sites')}
        >
          Back to Sites
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        title={
          current
            ? 'Edit Sharepoint Connection'
            : 'Create Sharepoint Connection'
        }
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => save()}
        okButtonProps={{ disabled: !selectedRowKeys.length }}
        width={850}
      >
        {!token ? (
          <Result
            title={
              authFailed ? 'Authentication failed' : 'Authentication required '
            }
            status={authFailed ? 'error' : 'info'}
            subTitle={
              authFailed ||
              'For security reasons you need to authenticate again'
            }
            extra={
              <Button
                type="primary"
                key="console"
                loading={graphAuthInProgress}
                onClick={() => authenticate()}
              >
                Login
              </Button>
            }
          />
        ) : (
          <Spin spinning={loading}>
            {/* Display spinner */}
            <Form layout="inline" style={{ marginBottom: 16 }}>
              <Form.Item style={{ width: '100%' }}>
                <Input
                  placeholder="Type to search"
                  value={searchTerm}
                  allowClear
                  onChange={(e) => setSearchTerm(e.target.value)}
                  ref={searchInputRef} // Reference to search input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Form>
            <ProList
              toolBarRender={() => {
                return [renderBackButton()];
              }}
              metas={{
                avatar: {
                  dataIndex: 'mimeType',
                  render: (_, it) => {
                    const icon: any = mimeToFileIcon[it?.file?.mimeType];
                    return icon?.() || <FaFile />;
                  },
                },
                title: {
                  dataIndex: 'name',
                },
                description: {
                  render: (_, item: any) => {
                    if (item.lastModifiedDateTime) {
                      if (!item.lastModifiedBy?.user?.displayName)
                        return `Last Modified: ${new Date(
                          item.lastModifiedDateTime,
                        ).toLocaleString()}`;
                      return `Last Modified: ${new Date(
                        item.lastModifiedDateTime,
                      ).toLocaleString()} by ${
                        item.lastModifiedBy?.user?.displayName || 'Unknown'
                      }`;
                    }
                    return 'No additional info available';
                  },
                },
                actions: {
                  render: (_, item: any) => {
                    return null; // Actions are no longer needed, handled by onClick
                  },
                },
              }}
              rowKey="id"
              headerTitle="Select Files or Folders"
              rowSelection={
                currentLevel === 'items'
                  ? {
                      ...rowSelection,
                      getCheckboxProps: (record) => ({
                        disabled: record.isParentFolder, // Disable checkbox for parent folder navigation
                      }),
                    }
                  : undefined
              }
              pagination={{
                pageSize: 7, // Set the page size to 15
              }}
              dataSource={filteredDataSource} // Use filtered data based on search
              onRow={(record) => ({
                onClick: () => handleListItemClick(record),
              })}
            />
          </Spin>
        )}
      </Modal>
      {contextHolder}
    </>
  );
};

export default FileSourceSharepointModal;
