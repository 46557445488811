import { PlusOutlined } from '@ant-design/icons';
import { Card, Dropdown, Layout, MenuProps, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationDelete, ApplicationModel } from '../../../data/application';
import { AppDispatch, RootState } from '../../../redux';
import { fetchApps } from '../../../redux/workspaces';
import ChatWindowAppsModal from './ChatWindowAppsModal';

import './styles.css';
import useWorkspace from '../../../utils/useWorkspace';
import { WORKSPACE_PERMISSION_LEVEL } from '../../../data/share';

const { Header, Content, Sider } = Layout;

const items: MenuProps['items'] = [
  {
    label: 'Edit',
    key: 'edit',
  },
  {
    label: 'Delete',
    key: 'delete',
    danger: true,
  },
];

interface ChatWindowAppsProps {
  setApp: (app: ApplicationModel) => void;
}

const ChatWindowApps: React.FC<ChatWindowAppsProps> = (params) => {
  const { setApp } = params;
  const { isAdmin, isCitizenDev } = useSelector(
    (state: RootState) => state.auth,
  );
  const { workspaceApps, workspace } = useSelector(
    (state: RootState) => state.workspaces,
  );
  const [modalApp, setModalApp] = useState<ApplicationModel>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [appsLoading, setAppsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { checkCurrentWorkspacePermissions } = useWorkspace();

  useEffect(() => {
    if (modalOpen === false && workspace?._id) updateAppsList();
  }, [workspace?._id, modalOpen]);

  const deleteApp = async (id: string) => {
    setAppsLoading(true);
    await applicationDelete(id);
    updateAppsList();
    setAppsLoading(false);
  };

  const updateAppsList = async () => {
    await dispatch(fetchApps()).unwrap();
  };

  const canEditApp = (app: ApplicationModel | undefined) => {
    // global apps can only by edited by admins
    if (app?.isGlobalDefault && !isAdmin) return false;

    // local apps can be edited by workspace manager
    return checkCurrentWorkspacePermissions(WORKSPACE_PERMISSION_LEVEL.MANAGE);
  };

  const onClick = (app: ApplicationModel, { key }: any) => {
    if (key === 'delete' && !app.isGlobalDefault) deleteApp(app._id);
    if (key === 'edit' && !app.isGlobalDefault) {
      setModalApp(app);
      setModalOpen(true);
    }
  };

  return (
    <Spin spinning={appsLoading}>
      <div style={{ padding: 10 }}>
        <Space size="middle" style={{ display: 'flex' }} wrap>
          {workspaceApps?.map((app: ApplicationModel) => (
            <Dropdown
              menu={{
                items: canEditApp(app) ? items : [],
                onClick: (e) => onClick(app, e),
              }}
              trigger={['contextMenu']}
            >
              <Card
                style={{ width: 300, cursor: 'pointer', textAlign: 'center' }}
                onClick={() => setApp(app)}
                className={'appCard'}
              >
                <p>{app.name}</p>
              </Card>
            </Dropdown>
          ))}

          {canEditApp(undefined) && (
            <Card
              style={{ width: 300, cursor: 'pointer', textAlign: 'center' }}
              onClick={() => {
                setModalApp(undefined);
                setModalOpen(true);
              }}
              className={'appCard'}
            >
              <p>
                <PlusOutlined /> Add application
              </p>
            </Card>
          )}
        </Space>
      </div>
      <ChatWindowAppsModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        current={modalApp}
      />
    </Spin>
  );
};

export default ChatWindowApps;
