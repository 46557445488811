import { Spin } from 'antd';
import { useEffect } from 'react';
import useGraphAPI from '../utils/useGraphAPI';

const GraphAPIAuthCallback = () => {
  const { callbackHandler } = useGraphAPI();

  useEffect(() => {
    callbackHandler();
  }, []);
  return (
    <div style={{ width: '100%', marginTop: 50 }}>
      <Spin tip="">
        <p style={{ textAlign: 'center' }}>Authentication successfull</p>
      </Spin>
    </div>
  );
};

export default GraphAPIAuthCallback;
