import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Authentication {
  // Zitadel
  //access_token?: string;
  sub?: string;
  name?: string;
  email?: string;
  given_name?: string;
  family_name?: string;
  'urn:zitadel:iam:user:resourceowner:id'?: string;
  'urn:zitadel:iam:org:project:roles'?: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

// can be used to flag features as ommax internal only (check against resourceowner of auth)
export const OMMAX_ORG_ID = '247938156106683657';

const adminClaim: string =
  process.env.REACT_APP_AUTH_ADMIN || 'delphi_administrator';
const citizenDevClaim: string =
  process.env.REACT_APP_AUTH_CITIZEN_DEV || 'delphi_citizen_developer';
const project: string =
  process.env.REACT_APP_AUTH_PROJECT || '247938156106683657';

const isAdmin = (auth: Authentication) =>
  auth['urn:zitadel:iam:org:project:roles']?.[adminClaim]?.[project];
const isCitizenDev = (auth: Authentication) =>
  auth['urn:zitadel:iam:org:project:roles']?.[citizenDevClaim]?.[project];

export interface IAuthState {
  auth: Authentication | null;
  isAdmin: boolean;
  isCitizenDev: boolean;
  isAuthenticated: boolean | null;
  error?: any; // error from backend
}

const initialState: IAuthState = {
  auth: null,
  isAdmin: false,
  isCitizenDev: false,
  isAuthenticated: null,
  error: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    localLogin: (state, action: PayloadAction<Authentication>) => {
      state.isAuthenticated = true;
      state.isAdmin = !!isAdmin(action.payload);
      state.isCitizenDev = !!isCitizenDev(action.payload);
      state.auth = action.payload;
    },
    localLogout: (state, action: PayloadAction) => {
      state.isAuthenticated = false;
      state.auth = null;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction) => {
      state.error = action.payload;
    },
  },
});

export const { localLogin, localLogout, setError } = slice.actions;

export default slice.reducer;
