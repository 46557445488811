import { FileOutlined, InboxOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Layout,
  message,
  Result,
  Row,
  Spin,
  UploadProps,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { FullPageChat } from 'flowise-embed-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationModel } from '../../../data/application';
import { AppDispatch, RootState } from '../../../redux';
import { fetchApps } from '../../../redux/workspaces';
import ChatWindowApps from './ChatWindowApps';
import { uploadFlow } from '../../../data/uploadFile';
import { RcFile } from 'antd/es/upload/interface';
import FileDrawer from './FileDrawer';
import useFileUpload from '../../../utils/useFileUpload';

const { Content } = Layout;

enum Page {
  APP_LIST,
  APP,
}

const ChatWindow: React.FC = () => {
  const { isFileDragging } = useSelector((state: RootState) => state.file);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { workspace, loadingApps, workspaceApps, workspaceFiles } = useSelector(
    (state: RootState) => state.workspaces,
  );
  const [app, setApp] = useState<ApplicationModel>();
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [isFileDrawerOpen, setIsFileDrawerOpen] = useState<boolean>(false);
  const currentChathistoryPrefix = useRef<string>();
  const [showReloadChatScreen, setShowReloadChatScreen] =
    useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();
  const { isUploading, getUploadProps } = useFileUpload();

  const updateAppsList = async () => {
    await dispatch(fetchApps()).unwrap();
  };

  useEffect(() => {
    if (loadingApps === 'succeeded' && page === undefined) {
      setApp(workspaceApps?.find((a) => a.isGlobalDefault));
      setPage(Page.APP);
    } else if (app && !workspaceApps?.find((a) => a._id === app!._id)) {
      setApp(undefined);
      setPage(Page.APP_LIST);
    }
  }, [loadingApps, app]);

  useEffect(() => {
    if (app && workspace) {
      // to be fixed directly in flowise code
      // currently, flowise can only handle one history per chatflow id
      // therefore, we reset the id on eery app / workspace change and hide the UI for a few seconds to enforce rebuild (as there is no useEffect on the chat id)
      setShowReloadChatScreen(true);

      // store current history for later use
      const currCache = localStorage.getItem(`${app.flowise.id}_EXTERNAL`);
      if (currCache) {
        localStorage.setItem(
          `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
          currCache,
        );
      } else {
        localStorage.removeItem(
          `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
        );
      }
      currentChathistoryPrefix.current = `${app._id}+${workspace._id}`;

      // retrieve new history
      const newCache = localStorage.getItem(
        `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
      );

      if (newCache)
        localStorage.setItem(`${app.flowise.id}_EXTERNAL`, newCache);
      else localStorage.removeItem(`${app.flowise.id}_EXTERNAL`);

      setTimeout(() => {
        setShowReloadChatScreen(false);
      }, 500);
    }
  }, [app, workspace]);

  useEffect(() => {
    if (app) {
      setPage(Page.APP);
    }
  }, [app]);
  useEffect(() => {
    if (workspace) updateAppsList();
  }, [workspace]);

  const getPage = () => {
    if ((isFileDragging || isUploading) && !isFileDrawerOpen) {
      return (
        <Row
          justify="space-around"
          align="middle"
          style={{ marginTop: 100, paddingLeft: 20, paddingRight: 20 }}
        >
          <Col span={24}>
            <Dragger {...getUploadProps(workspace!, messageApi)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a PDF files only at the moment.
              </p>
            </Dragger>
          </Col>
        </Row>
      );
    } else if (page === Page.APP_LIST) {
      return <ChatWindowApps setApp={setApp} />;
    } else {
      if (!app)
        return (
          <Result
            status="404"
            title="App not found"
            subTitle="Sorry, the app you requested was not found."
          />
        );
      if (showReloadChatScreen) {
        return <Spin style={{ height: 100 }}></Spin>;
      }

      return (
        <>
          <FullPageChat
            chatflowid={app!.flowise!.id}
            // @ts-ignore
            chatId={workspace?._id + '_' + auth?.sub}
            apiHost="https://flowise.delphi-dialogue.com"
            chatflowConfig={{
              vars: {
                customerId: workspace?._id + '+' + auth?.sub,
              },
              qdrantCollection: workspace?._id,
              sessionId: workspace?._id + '+' + auth?.sub,
              returnSourceDocuments: true,
            }}
            // @ts-ignore
            theme={{
              chatWindow: {
                showTitle: true,
                title: 'Delphi Dialogue | ' + app!.name,
                titleAvatarSrc:
                  'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
                welcomeMessage: 'Welcome to Delphi Dialogue!',
                backgroundColor: '#ffffff',
                height: 700,
                fontSize: 16,
                poweredByTextColor: '#303235',
                botMessage: {
                  backgroundColor: '#f7f8ff',
                  textColor: '#303235',
                  showAvatar: true,
                  avatarSrc: 'https://www.ommax-digital.com/favicon.ico',
                },
                userMessage: {
                  backgroundColor: '#2077DA',
                  textColor: '#ffffff',
                  showAvatar: true,
                  avatarSrc:
                    'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
                },
                textInput: {
                  placeholder: 'How can Delphi help you today?',
                  backgroundColor: '#ffffff',
                  textColor: '#303235',
                  sendButtonColor: '#11BADD',
                },
                feedback: {
                  color: '#303235',
                },
                footer: {
                  textColor: '#303235',
                  text: 'Powered by',
                  company: 'OMMAX Delphi Dialogue',
                  companyLink: 'https://www.ommax.de',
                },
              },
            }}
          />
        </>
      );
    }
  };

  return (
    <Content
      style={{
        margin: '0px 16px',
        padding: 0,
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Row
          justify="space-between"
          style={{
            padding: 5,
            backgroundColor: '#3A00D4',
            color: 'white',
            borderBottom: 1,
            borderBottomColor: '#f0efed',
            borderBottomStyle: 'solid',
          }}
        >
          <Col style={{ width: 100 }}>
            {page === Page.APP && (
              <Button onClick={() => setPage(Page.APP_LIST)}>
                <LeftOutlined /> App List
              </Button>
            )}
          </Col>
          <Col>
            <p style={{ fontSize: 20, margin: 3 }}>
              {page === Page.APP_LIST ? 'App List' : app?.name}
            </p>
          </Col>
          <Col>
            <Badge count={workspaceFiles?.length} color="default">
              <Button onClick={() => setIsFileDrawerOpen(true)}>
                <FileOutlined /> Files
              </Button>
            </Badge>
          </Col>
        </Row>
        <Spin spinning={loadingApps !== 'succeeded'}>{getPage()}</Spin>
      </div>
      <FileDrawer
        isVisible={isFileDrawerOpen}
        setIsVisible={setIsFileDrawerOpen}
      />
      {contextHolder}
    </Content>
  );
};

export default ChatWindow;
