export function generateInitials(name: string): string {
    const nameParts = name.trim().split(' ');
    
    if (nameParts.length >= 2) {
        // Return the first and last initials
        return nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    } else {
        // Return the first two characters of the first name
        return nameParts[0].slice(0, 2).toUpperCase();
    }
}