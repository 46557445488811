import { Avatar, Button, List, message, Select } from 'antd';
import { useState } from 'react';
import { ShareModel, WORKSPACE_PERMISSION_LEVEL } from '../../data/share';
import { generateInitials } from '../../utils/avatar';
import AzureUserSearch from './AzureUserSearch';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const data = [
  {
    userId: '1',
    permissionLevel: WORKSPACE_PERMISSION_LEVEL.READ,
    user: {
      displayName: 'test',
      id: '1',
    },
  },
];

interface Members {}

interface AzureUserListProps {
  showAddBar: boolean;
}

const permissions = [
  {
    label: 'User',
    value: WORKSPACE_PERMISSION_LEVEL.READ,
  },
  /*{
    label: 'Manager',
    value: WORKSPACE_PERMISSION_LEVEL.MANAGE,
  },*/
  {
    label: 'Owner',
    value: WORKSPACE_PERMISSION_LEVEL.OWN,
  },
];

const AzureUserList: React.FC<AzureUserListProps & any> = (props) => {
  const {
    placeholder = 'Type to search user',
    showAddBar = false,
    ...otherProps
  } = props;
  //const [users, setUsers] = useState<any>(data);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const auth = useSelector((state: RootState) => state.auth.auth);

  const users = props.value;
  const setUsers = props.onChange;

  const addUser = (user: any) => {
    // check if duplicate
    if (users?.find((i: any) => i.userId === user.id)) {
      messageApi.error('Workspace already shared with ' + user.displayName);
      return;
    }

    // add
    setUsers([
      {
        userEmail: user.mail,
        userName: user.displayName,
        isUnclaimed: true,
        permissionLevel: WORKSPACE_PERMISSION_LEVEL.READ,
        user,
      },
      ...users,
    ]);
  };

  const setUserPermission = (u: any, permissionLevel: any) => {
    setUsers(
      users.map((user: any) => ({
        ...user,
        permissionLevel:
          (user.userEmail === u.userEmail && user.userEmail) ||
          (user.userId && user.userId === u.userId)
            ? permissionLevel
            : user.permissionLevel,
      })),
    );
  };

  const removeUser = (user: any) => {
    setUsers(
      users.filter(
        (i: any) =>
          !(user.userEmail === i.userEmail && !!user.userEmail) &&
          !(!!user.userId && user.userId === i.userId),
      ),
    );
  };

  return (
    <>
      {contextHolder}
      {showAddBar && (
        <AzureUserSearch
          placeholder="Type to search user to share workspace with"
          style={{ width: '100%' }}
          onChange={(user: any) => addUser(user)}
        />
      )}
      <List<ShareModel>
        itemLayout="horizontal"
        dataSource={users}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Select
                value={item.permissionLevel}
                style={{ width: 120 }}
                options={permissions}
                onSelect={(permissionLevel) =>
                  setUserPermission(item, permissionLevel)
                }
                disabled={auth!.sub === item.userId}
                variant="borderless"
              />,
              <Button
                danger
                type="link"
                onClick={() => removeUser(item)}
                disabled={auth!.sub === item.userId}
              >
                remove
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  children={generateInitials(item.userName || item.userId)}
                />
              }
              title={item.userName || item.userId}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default AzureUserList;
