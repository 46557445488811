import {
  LogoutOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Space,
  Tag,
  Tooltip,
  theme,
} from 'antd';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customization } from '../../config/customization';
import { RootState } from '../../redux';
import { userManager } from '../AuthWrapper';
import logo from './logo.svg';
import { setDragging } from '../../redux/file';

const { Header, Content, Footer } = Layout;

/*
 * Layout
 * General layout component
 * Handles things such as header and footer; defines content window
 */

interface AiLayoutProps {
  children?: React.ReactNode;
}

const ISSUER = process.env.REACT_APP_ZITADEL_ISSUER as string;

const OmmaxLayout: React.FC<AiLayoutProps> = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { auth, isAdmin, isCitizenDev } = useSelector(
    (state: RootState) => state.auth,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dragTimeout = useRef<any>(null);

  const handleDragEnter = () => {
    if (dragTimeout.current) {
      clearTimeout(dragTimeout.current); // Clear any existing timeout when re-entering
    }
    dispatch(setDragging(true)); // Set dragging to true immediately
  };

  const handleDragLeave = () => {
    dragTimeout.current = setTimeout(() => {
      dispatch(setDragging(false)); // Set dragging to false after 1 second
    }, 1000); // 1 second delay
  };

  const profile = () => {
    window?.open(ISSUER + '/ui/console/users/me', '_blank')?.focus();
  };

  const logoutUser = () => {
    userManager.signoutRedirect();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <span onClick={() => profile()}>Profile</span>,
      icon: <ProfileOutlined />,
    },
    {
      key: '2',
      label: <span onClick={() => logoutUser()}>Logout</span>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 35,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className="logo"
            style={{
              backgroundImage: `url(${logo})`,
              width: 100,
              height: 20,
              backgroundSize: '100%',
            }}
          />
          <Menu theme="dark" mode="horizontal" />
        </div>
        <div>
          <Space>
            {isAdmin && <Tag color="volcano">Admin</Tag>}
            {!isAdmin && isCitizenDev && (
              <Tag color="cyan">Citizen Developer</Tag>
            )}

            {(isAdmin || isCitizenDev) && (
              <Button
                type="link"
                style={{ color: 'white' }}
                onClick={() => navigate('/flowiseProxy')}
              >
                Open Flowise
              </Button>
            )}
            <Dropdown menu={{ items }} overlayStyle={{ paddingTop: 10 }}>
              <span style={{ color: 'white' }}>
                <Space>
                  <Avatar icon={<UserOutlined />} />
                  {auth?.name || 'Not logged in'}
                </Space>
              </span>
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Content>
        <Layout style={{ minHeight: '80vh' }}>
          <Content
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Space wrap>
          <Button type="link" href="https://www.ommax-digital.com/en/imprint/">
            Imprint
          </Button>
          <Button
            type="link"
            href="https://www.ommax-digital.com/en/privacy-policy/"
          >
            Privacy Policy
          </Button>
          {process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== 'prod' && (
              <Tooltip title={'Environment: ' + process.env.REACT_APP_ENV}>
                <Tag color="cyan">{process.env.REACT_APP_ENV}</Tag>
              </Tooltip>
            )}
        </Space>
        <br />
        {customization.copyright}
      </Footer>
    </Layout>
  );
};

export default OmmaxLayout;
