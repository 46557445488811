import { Form } from 'antd';
import FileSourceList from '../form/FileSourceList';
import {
  formItemLayoutFull,
  WorkspaceEditChildModalProps,
} from './WorkspaceEditModal';

const WorkspaceFiles: React.FC<WorkspaceEditChildModalProps> = (params) => {
  //const form = Form.useFormInstance();
  const { form, current, listOnly } = params;
  const isPublic = Form.useWatch('isPublic', form);

  return (
    <>
      <Form.Item name="fileSources" {...formItemLayoutFull}>
        <FileSourceList listOnly={listOnly} />
      </Form.Item>
    </>
  );
};

export default WorkspaceFiles;
