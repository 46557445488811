import { request } from "../utils/request";
import { BackendResponse } from "./data";

export interface ApplicationModel {
  _id: string;
  type: string;
  isGlobalDefault: boolean;
  isEnabled: boolean;
  isPublic: boolean;
  flowise: {
    id: string;
    allowFileUpload: boolean;
  }
  name: string;
  createdAt: Date;
  updatedAt: Date;
}


export const applicationList = async (workspaceId: string) => {
  const res = await request<BackendResponse<ApplicationModel[]>>('/workspace/'+workspaceId+"/apps", {
    method: 'get',
  });
  return res;
};


export const applicationUpdate = async (appId: string, data: any) => {
  const res = await request<BackendResponse<ApplicationModel>>(`/app/${appId}`, {
    method: 'patch',
    data,
  });
  return res;
};


export const applicationCreate = async (data: Partial<ApplicationModel>) => {
  const res = await request<BackendResponse<ApplicationModel>>('/app', {
    method: 'put',
    data,
  });
  return res;
};

export const applicationDelete = async (appId: string) => {
  const res = await request<any>(`/app/${appId}`, {
    method: 'delete',
  });
  return res;
};