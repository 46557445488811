import { Form, Input, Layout, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { applicationCreate, ApplicationModel, applicationUpdate } from '../../../data/application';
import { RootState } from '../../../redux';

const { Header, Content, Sider } = Layout;

interface ChatWindowAppsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  current?: ApplicationModel;
}

export const formItemLayoutFull = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const ChatWindowAppsModal: React.FC<ChatWindowAppsModalProps> = (params) => {
  const { isOpen, setIsOpen, current } = params;
  const {workspace} = useSelector((state: RootState) => state.workspaces)
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
   
  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({...current})
    } else {
      form.resetFields()
    }
  }, [isOpen])

  const save = async () => {
    const values = await form.validateFields();
    setLoading(true)
    if (current) {
      const res = await applicationUpdate(current._id, {
        ...current,
        ...values,
        _id: undefined
      })
    }
    else {
      const res = await applicationCreate({
        ...values,
        workspaceId: workspace!._id,
        type: "flowise"
      })
    }
    setLoading(false)
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        title={current ? 'Edit App' : 'Create App'}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        confirmLoading={isLoading}
        width={1024}
        onOk={save}
        okText="Save"
      >
        <Spin spinning={isLoading}>
        <Form
          form={form}
          name="app"
          {...formItemLayout}
          onFinish={save}
          style={{ marginTop: 15 }}
          preserve={true}
          onSubmitCapture={() => save()}
        >
          <Form.Item
            name="name"
            label="Name"
            extra="Public visible name that describes this app"
            rules={[{ required: true, message: 'Please choose an app name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["flowise", "id"]}
            label="Flowise ID"
            extra="Chatflow identifier for this app"
            rules={[{ required: true, message: 'Please input a Flowise ID' }]}
          >
            <Input />
          </Form.Item>
        </Form>
        </Spin>
      </Modal>
    </>
    )
}

export default ChatWindowAppsModal