import { InboxOutlined } from '@ant-design/icons';
import { ProList } from '@ant-design/pro-components';
import {
  Spin,
  Alert,
  Tabs,
  Row,
  Col,
  Typography,
  message,
  TabsProps,
  Form,
  Button,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { FaFile } from 'react-icons/fa';
import {
  fileDelete,
  fileDownloadLink,
  RegisterFileResponse,
} from '../../data/uploadFile';
import { mimeToFileIcon } from '../../utils/fileIcon';
import modal from 'antd/es/modal';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux';
import useFileUpload from '../../utils/useFileUpload';
import { getWorkspaceById, WorkspaceModel } from '../../data/workspace';
import AnimateHeight from 'react-animate-height';
import './index.css';
import WorkspaceFiles from '../workspace/WorkspaceFiles';
import { useForm } from 'antd/lib/form/Form';
import { current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface FileMenuProps {
  workspace: WorkspaceModel;
  files: RegisterFileResponse[];
  isLoadingFiles?: boolean;
  updateFileList: () => void;
  isVisible?: boolean;
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Uploaded files',
  },
  {
    key: '2',
    label: 'Synchronized file sources',
  },
  {
    key: '3',
    label: 'Generated files',
  },
];

const FileMenu: React.FC<FileMenuProps> = (props) => {
  const { files, isLoadingFiles, workspace, updateFileList, isVisible } = props;
  const { isFileDragging } = useSelector((state: RootState) => state.file);
  const [tab, setTab] = useState<string>('1');
  const [isInternalLoading, setInternalLoading] = useState<boolean>(false);
  const [deletingInProgressFile, setDeletingInProgressFile] =
    useState<RegisterFileResponse>();
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();
  const { isUploading, getUploadProps } = useFileUpload();

  const [form] = useForm();

  const uploadFileIsSmall = isFileDragging || isUploading;

  const handleDownload = async (record: RegisterFileResponse) => {
    const link = await fileDownloadLink(record._id);
    window?.open(link.url, '_blank')?.focus();
  };

  const loadWorkspace = async () => {
    setInternalLoading(true);
    const data = await getWorkspaceById(workspace!._id);
    form.setFieldsValue({
      ...data.data,
      expiryDate: dayjs(data.data.expiryDate),
    });
    setInternalLoading(false);
  };

  const deleteFile = (file: RegisterFileResponse) => {
    modal.confirm({
      title: 'Delete file ' + file?.filename,
      content:
        'Are you sure that you want to delete the file ' +
        file?.filename +
        '? This can not be undone.',
      async onOk() {
        setDeletingInProgressFile(file);
        try {
          await fileDelete(file!._id, { workspaceId: workspace!._id });
          messageApi.success(
            'File ' + file?.filename + ' was successfully deleted',
          );
          updateFileList();
        } catch (e: any) {
          messageApi.error(
            'File ' + file?.filename + ' could not be deleted: ' + e?.message,
          );
        }
        setDeletingInProgressFile(undefined);
      },
    });
  };

  useEffect(() => {
    if (workspace) loadWorkspace();
  }, [isVisible]);
  const GetContent = () => {
    if (tab === '2')
      return (
        <>
          <Typography.Text type="secondary">
            The workspace manager can add or remove file sources in the
            workspace menu
          </Typography.Text>
          <Form form={form}>
            <WorkspaceFiles
              current={workspace}
              isLoading={isInternalLoading}
              setLoading={setInternalLoading}
              form={form}
              listOnly={true}
            />
          </Form>
        </>
      );
    if (['1', '3'].includes(tab))
      return (
        <>
          <Typography.Text type="secondary">
            {tab === '1'
              ? 'You can upload files that are available in this workspace'
              : 'Applications can generate files which are visible here'}
          </Typography.Text>
          <ProList<RegisterFileResponse>
            rowKey="_id"
            dataSource={(files || [])?.filter?.(
              (i) => i.isSystemGenerated === (tab === '3'),
            )}
            showActions="hover"
            onRow={(record) => ({
              onClick: () => handleDownload(record),
            })}
            pagination={{
              pageSize: 10,
            }}
            metas={{
              avatar: {
                dataIndex: 'mimeType',
                render: (_, it) => {
                  const icon: any = mimeToFileIcon[it.mimeType];
                  return icon?.() || <FaFile />;
                },
              },
              title: {
                dataIndex: 'filename',
                render: (_, it) => {
                  return (
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      style={{ maxWidth: 250 }}
                    >
                      {it.filename}
                    </Typography.Text>
                  );
                },
              },
              actions: {
                render: (text, row, index, action) => [
                  <Button type="link" onClick={() => deleteFile(row)} danger>
                    remove
                  </Button>,
                ],
              },
            }}
          />
        </>
      );
    return null;
  };

  return (
    <>
      <Spin spinning={isLoadingFiles || isInternalLoading}>
        <AnimateHeight
          id="fileDialogUpload"
          duration={500}
          height={uploadFileIsSmall ? 190 : 65}
          className={
            'fileUpload ' + (!uploadFileIsSmall ? 'fileUploadSmall' : '')
          }
        >
          <Row
            justify="space-around"
            align="middle"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <Col span={24}>
              <Dragger {...getUploadProps(workspace!, messageApi)}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a PDF files only at the moment.
                </p>
              </Dragger>
            </Col>
          </Row>
        </AnimateHeight>

        <Tabs activeKey={tab} items={items} onChange={(val) => setTab(val)} />

        <GetContent />
      </Spin>
      {contextHolder}
    </>
  );
};

export default FileMenu;
