import { ResponseError, extend } from 'umi-request';
const { REACT_APP_BACKEND, REACT_APP_PYTHON_BACKEND } = process.env;

/*
 * Helper for requests
 * Adds the base url to all requests and handles errors
 * See https://github.com/umijs/umi-request
 */

const errorHandler = function (error: ResponseError<any>) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.status);
    console.log(error.response.headers);
    console.log(error.data);
    console.log(error.request);
  } else {
    // The request was made but no response was received or error occurs when setting up the request.
    console.log(error.message);
  }
  throw error;
};

const request = extend({
  prefix: REACT_APP_BACKEND || '/api-ts',
  timeout: 60000 * 5,
  errorHandler,
  redirect: 'follow',
});

request.use(
  async (ctx, next) => {
    await next();
  },
  { core: true },
);

const request_python = {
  prefix: REACT_APP_PYTHON_BACKEND,
};

request.use(
  async (ctx, next) => {
    await next();
  },
  { core: true },
);

export { request, request_python };
