import { Form, Input, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { FileSource } from '../../data/fileSource';
import { ConfluenceSpace, getConfluenceSpaces } from '../../data/confluenceSource';

interface FileSourceConfluenceModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  current?: FileSource;
  updateFileSource: (source: Partial<FileSource>) => void;
}

export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const FileSourceS3Modal: React.FC<FileSourceConfluenceModalProps> = (
  params,
) => {
  const { isOpen, setIsOpen, current, updateFileSource } = params;
  const [spaceList, setSpaceList] = useState<ConfluenceSpace[]>([]);

  const [form] = Form.useForm();
  const space = Form.useWatch('space', form);
  const spaceId = useMemo(() => spaceList.find(i => i.name === space)?.id, [space])


  const loadData = async () => {
    const list = await getConfluenceSpaces();
    setSpaceList(list.data);
  };

  // on change of current, set values
  useEffect(() => {
    form.setFieldsValue({
      bucket: current?.s3BucketName,
      folder: current?.s3Folder
    });
  }, [current]);

  // on close, reset values
  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    } else {
      loadData();
    }
  }, [isOpen]);

  const save = async () => {
    const fieldsValue = await form.validateFields();
    await updateFileSource({
      type: 's3',
      s3BucketName: fieldsValue.bucket,
      s3Folder: fieldsValue.folder,
      humanReadableName: fieldsValue.bucket + "/" + fieldsValue.folder
    });
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        title={
          current
            ? 'Edit S3 Connection'
            : 'Create S3 Connection'
        }
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => save()}
        width={600}
      >
        <Form
          form={form}
          name="s3"
          {...formItemLayout}
          onFinish={save}
          style={{ marginTop: 15 }}
        >
          <Form.Item
            name="bucket"
            label="S3 bucket"
            extra="Select the S3 bucket to connect"
            required
          >
            <Input required />
          </Form.Item>
          <Form.Item
            name="folder"
            label="S3 folder"
            extra="Select the S3 folder to connect"
            required
          >
            <Input required />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FileSourceS3Modal;
