import { UserOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Spin } from 'antd';
import { useState } from 'react';
import { getShareUserList, ShareUserModel } from '../../data/share';

const AzureUserSearch: React.FC<any> = (props) => {
  const {
    placeholder = 'Type to search user',
    onChange,
    ...otherProps
  } = props;
  const [selected, setSelected] = useState<string>();
  const [userList, setUserList] = useState<ShareUserModel[]>([]);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const updateSearch = async (val: string) => {
    if (!val) {
      setOptions([]);
    } else {
      setLoading(true);
      const list = await getShareUserList(val);
      const mappedList = list?.map((i) => ({
        value: i.displayName,
        option: i.id,
      }));
      setOptions(mappedList || []);
      setUserList(list);
      setLoading(false);
    }
  };

  const submit = (userId: string) => {
    // get entire user object
    const user = userList?.find((i) => i.id === userId);
    onChange?.(user);
    setSelected(undefined);
  };

  //  addonBefore={<UserOutlined />}
  return (
    <AutoComplete
      options={
        isLoading
          ? [
              {
                value: 'NULL',
                disabled: true,
                label: (
                  <div className="flex items-center justify-center w-full h-[100px]">
                    <Spin size="large" />
                  </div>
                ),
              },
            ]
          : options
      }
      size="large"
      value={selected}
      onChange={setSelected}
      onSearch={(val) => updateSearch(val)}
      onSelect={(_, opt) => submit(opt.option)}
      {...otherProps}
    >
      <Input size="large" placeholder={placeholder} allowClear />
    </AutoComplete>
  );
};

export default AzureUserSearch;
