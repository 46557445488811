import { ThemeConfig } from 'antd';

/*
 * Design
 * Overrides antd's default design
 * @see https://ant.design/docs/react/customize-theme
 */

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#000F50',
    colorFillSecondary: '#02FEE1',
    controlItemBgActive: "lightyellow"
  },
  components: {
      Table: {
          rowSelectedBg: 'lightyellow'
      }
  }
};

export { theme };
